import { Component, input } from '@angular/core';

@Component({
  selector: 'app-filter-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.375 15.5V8.5M16.625 22.5V19.875M4.375 22.5V19M16.625 16.375V8.5M10.5 11.125V8.5M10.5 22.5V14.625"
      [attr.stroke]="baseColor()"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M4.375 19C5.3415 19 6.125 18.2165 6.125 17.25C6.125 16.2835 5.3415 15.5 4.375 15.5C3.4085 15.5 2.625 16.2835 2.625 17.25C2.625 18.2165 3.4085 19 4.375 19Z"
      [attr.stroke]="baseColor()"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M10.5 14.625C11.4665 14.625 12.25 13.8415 12.25 12.875C12.25 11.9085 11.4665 11.125 10.5 11.125C9.5335 11.125 8.75 11.9085 8.75 12.875C8.75 13.8415 9.5335 14.625 10.5 14.625Z"
      [attr.stroke]="baseColor()"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M16.625 19.875C17.5915 19.875 18.375 19.0915 18.375 18.125C18.375 17.1585 17.5915 16.375 16.625 16.375C15.6585 16.375 14.875 17.1585 14.875 18.125C14.875 19.0915 15.6585 19.875 16.625 19.875Z"
      [attr.stroke]="baseColor()"
      stroke-width="2"
      stroke-linecap="round"
    />
    @if (showCircle()) {
      <circle
        cx="19"
        cy="7"
        r="5.5"
        [attr.fill]="fill()"
        [attr.stroke]="stroke()"
        stroke-width="3"
      />
    }
  </svg> `,
})
export class FilterIconComponent {
  stroke = input<string>('white');
  fill = input<string>('#629EE4');
  baseColor = input<string>('#050609');
  showCircle = input<boolean | undefined>(undefined);
}
